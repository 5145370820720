<template>
  <div ref="body">
    <custom-header active="global" :subActive="current"></custom-header>
    <!-- <div class="banner-container">
      <van-swipe ref="banner" :autoplay="5000" class="fill">
        <van-swipe-item v-for="(item, idx) in banners" :key="idx">
          <div class="fill" @click="urlLink(item.url)">
            <img :src="item.bannerPicture" alt="" style="width: 100%; height: auto;">
          </div>
        </van-swipe-item>
      </van-swipe>
    </div> -->
    <div class="content-container">
      <!-- <h1 class="text-bold">Previous Editions</h1>
      <div class="flex flex-wrap">
        <div v-for="(item,index) in years" :key="index" :class="['item', idx === index ? 'active' : '',]" @click="showContent(index)">
          <div class="box" :style="{'background-color': item.color}">
            {{item.number}}
            <van-icon name="play" :color="item.color" class="down-icon" />
          </div>
          <div class="intro text-center" v-html="item.title"></div>
        </div>
      </div> -->
    
      <div class="history-content" v-html="desc"></div>
    </div>
    
    <custom-footer :friendLink="friendLink"></custom-footer>
    
    <!-- 回到顶部 -->
    <scroll-top @scrollTop="scrollTop"></scroll-top>
  </div>
</template>

<script>
import { Swipe, SwipeItem, Icon } from 'vant';
import customHeader from "./components/head.vue";
import customFooter from "./components/foot.vue";
import scrollTop from "./components/scrollTop.vue";
import commonMixin from './commonMixin.js';
import { getExhibitionRecords } from '@/api/index.js';

export default {
  // name: '往届概述',
  components: {
    'custom-header': customHeader,
    'custom-footer': customFooter, 'scroll-top': scrollTop,
    [Icon.name]: Icon,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
  },
  mixins: [commonMixin],
  metaInfo: {
    title: 'SZCW & SIFEChina 2025: Interational Home Furnishing Exhibition', // set a title
    meta: [
      { name: 'keyWords', content: 'Furniture Fair,Home Design,Furniture Exhibitor,Home Furnishing Forum,Design Week,Home Design Week' },
      { name: 'description', content: '2025 Marks 10 Years Organizing the lDC for Emerging Designers and Design Studios. Li Ximi, Cai Liechao, & Zhou Anbin, Participated in Previous Editions.'},
    ],
  },
  data() {
    return {
      current: 'history',
      idx: -1,
      years: [],
      desc: '',
    }
  },
  created() {
    this.getHistorys();
  },
  methods: {
    showContent(idx) {
      this.idx = idx;
      this.desc = this.years[idx].introduction;
    },
    getHistorys() {
      getExhibitionRecords().then(res => {
        if (res.resultStatus) {
          this.years = res.resultData;
          if (this.years.length > 0) {
            // this.showContent(this.years.length - 1);
            this.showContent(0);
          }
        }
      })
    },
  },
}
</script>

<style>
/*  .history-content img {
    display: block;
    width: 60%;
    height: auto;
    margin: 5px auto!important;
  } */
</style>
<style scoped lang="less">

// PC
@media screen and (min-width: 1024px) {
  .banner-container {
    width: 100%;
    
    .banner {
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% auto;
    }
  }
  .content-container {
    width: 280px;
    margin: 20px auto ;
    
    h1 {
      text-align: center;
      font-size: 6px;
      margin-bottom: 8px;
    }
    
    .item {
      width: 50px;
      margin: 0 10px 15px;
      padding: 5px;
      cursor: pointer;
      border-radius: 6Px;
      
      &.active,
      &:hover {
        box-shadow: 0 2Px 10Px rgba(0, 0, 0, .2);
      }
      
      .box {
        position: relative;
        width: 40px;
        height: 30px;
        line-height: 30px;
        border-radius: 1px;
        text-align: center;
        font-size: 10px;
        font-weight: bold;
        margin: 0 auto 5px;
        color: #fff;
        
        .down-icon {
          position: absolute;
          left: 17px;
          bottom: -3.2px;
          z-index: 1;
          font-size: 5px;
          transform: rotate(90deg);
        }
      }
    
      .intro {
        width: 40px;
        font-weight: bold;
        font-size: 3px;
        line-height: 1.4em;
        margin: 0 auto;
      }
    }
  
    .history-content {
      width: 260px;
      margin: 0 auto;
      font-size: 3.6px;
      
      /deep/ img {
        display: block;
        width: 50%;
        height: auto;
        margin: 5px auto!important;
      }
    }
  }
  
}

// 移动端
@media screen and (max-width: 1023px) {
  .banner-container {
    width: 100%;
    
    .banner {
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% auto;
    }
  }
  
  .content-container {
    width: 360px;
    margin: 20px auto ;
    
    h1 {
      text-align: center;
      font-size: 24px;
      margin-bottom: 20px;
    }
    
    .item {
      width: 80px;
      margin: 0 5px 15px;
      padding: 5px;
      cursor: pointer;
      border-radius: 6Px;
      
      &.active,
      &:hover {
        box-shadow: 0 2Px 10Px rgba(0, 0, 0, .2);
      }
      
      .box {
        position: relative;
        width: 70px;
        // height: 40px;
        line-height: 46px;
        border-radius: 4px;
        text-align: center;
        font-size: 16px;
        font-family: 'Poppins-Medium';
        margin: 0 auto 15px;
        color: #fff;
        
        .down-icon {
          position: absolute;
          bottom: -8px;
          left: 40%;
          transform: translateX(-50%);
          z-index: 1;
          font-size: 15px;
          transform: rotate(90deg);
        }
      }
    
      .intro {
        width: 100%;
        font-weight: bold;
        font-size: 10px;
        line-height: 1.4em;
        margin: 0 auto;
      }
    }
  
    .history-content {
      width: 320px;
      margin: 20px auto 40px;
      font-size: 14px;
      
      /deep/ img {
        display: block;
        width: 100%;
        height: auto;
        margin: 5Px auto!important;
      }
    }
  }
  
}

</style>